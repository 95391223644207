<template>
  <footer class="ya-te-footer bg-black">
    <div class="py-5 text-center text-co-gray-500">
      <small>
        &copy;&nbsp;LY&nbsp;Corporation
        <br>
        &copy;&nbsp;Brewus,Inc.
      </small>
    </div>
  </footer>
</template>
